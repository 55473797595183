import ContactForm from "./ContactForm"
import './Contact.css'
const ContactComponent = ()=>{
    return (
        <div className="contact-us-page">
            <ContactForm />
        </div>
    )
}

export default ContactComponent