import React from "react";
import './FooterComponent.css'

export default function Footer(){
    return(
        <>
            <div className="templatemo-footer">
                <div className="footer_bottom_content text-center">All Rights Reserved. Copyright ©2025.</div>
            </div>
        </>
    )
}